<template>
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">任务进度管理</h2>
        <div class="searSty">
          <div>
            <el-select v-model="taskItem" clearable placeholder="请选择任务类型" @change="taskChange(taskItem)">
              <el-option v-for="item in getTaskOptions" :key="item.taskType" :label="item.taskName" :value="item.taskType">
              </el-option>
            </el-select>
          </div>
          <div>
            <el-input v-model="taskName" clearable placeholder="请填写任务名称" @keyup.enter.native="searchList" @blur="searchList" @clear="searchList"></el-input>
          </div>
        </div>
        <el-table :data="tableData" border center>
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column prop="taskType" label="任务类型" align="center">
          </el-table-column>
          <el-table-column prop="taskName" label="任务名称" align="center">
          </el-table-column>
          <el-table-column prop="allName" label="任务负责人" align="center">
          </el-table-column>
          <el-table-column prop="status" label="任务节点" align="center">
          </el-table-column>
          <el-table-column prop="finishTime" label="任务截止时间" align="center">
          </el-table-column>
          <el-table-column label="剩余时间" align="center">
            <div slot-scope="scope" v-html="remainderA(scope.row.remainder)"></div>
          </el-table-column>
          <el-table-column label="操作" align="center" width="100">
            <template style="display: flex" slot-scope="scope">
              <el-button size="mini" type="primary" @click="assPer(scope.row)"><i>详情</i></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
        </el-pagination>
      </el-main>
    </el-container>

    <el-dialog title="任务进度管理" :visible.sync="persondialogVisible" width="600px" :before-close="persondialogVisibleClose" top="1vh">
      <el-table
          ref="multipleTable"
          :data="pertableData"
          border
          center>
      <el-table-column type="index" label="序号" width="55" align="center">
      </el-table-column>
      <el-table-column
          prop="executor"
          label="任务执行人"  align="center">
      </el-table-column>
        <el-table-column
            prop="statusName"
            label="任务节点"  align="center">
        </el-table-column>
      </el-table>
      <el-pagination background @current-change="handperleCurrentChange" :current-page="pageperNum" :page-size="pageperSize" layout="prev, pager, next" :total="pertotal">
      </el-pagination>

      <div class="btnDiv">
        <el-button type="primary" @click="persondialogVisibleClose()">关闭</el-button>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import _qs from "qs";
export default {
  data() {
    return {
      taskName:"",
      getTaskOptions:[],
      pertableData: [],
      multipleSelection: [],
      persondialogVisible:false,
      // 当前页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      // 总数
      total: 1,
      pertotal:1,
      pageperNum: 1,
      pageperSize: 10,
      // 表格数据
      tableData: [],
      // 学组用户点击修改的id
      userId: "",
      studyGroupId:'',
      studyStatus:'',
      assdepartmentId:'',
      taskItem:"",
      taskId:""
    };
  },
  created() {
    this.getTaskList();
    this.getTaskTypeList()
  },
  methods: {
    remainderA(time){
      if(!time){
        return time;
      }
      if(time.indexOf('-')!==-1){
        return '<div style="color: white;\n' +
            '    border: #ed4014;\n' +
            '    background-color: #ed4014;\n' +
            '    font-weight: 600;\n' +
            '    align-content: center;\n' +
            '    border-radius:25px;">'+time+'</div>';
      }
      if(time.indexOf('3天')!==-1 || time.indexOf('2天')!==-1 || time.indexOf('1天')!==-1 || time.indexOf('天') ===-1){
        return '<div style="color: white;\n' +
            '    border: #fc984b;\n' +
            '    background-color: #fc984b;\n' +
            '    font-weight: 600;\n' +
            '    align-content: center;\n' +
            '    border-radius:25px;">'+time+'</div>';
      }

      return '<div style="color: white;\n' +
          '    border: #0bc037;\n' +
          '    background-color: #0bc037;\n' +
          '    font-weight: 600;\n' +
          '    align-content: center;\n' +
          '    border-radius:25px;">'+time+'</div>';
    },
    searchList(){
      this.pageNum = 1;
      this.getTaskList();
    },
    taskChange(val){
      this.pageNum = 1;
      // console.log(val)
      this.taskItem=val
      this.getTaskList();
    },
    async getTaskTypeList(){
      let res = await this.$axios.getTaskTypeList();
      // console.log(res)
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.data.code == 200) {
        // console.log(res.data.data)
        this.getTaskOptions=res.data.data
        this.getTaskList()
      } else {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
      }
    },
    change(e) {
      this.$forceUpdate(e);
    },
    // 学组管理分页
    handleCurrentChange(val) {
      this.pageNum = val;
      // console.log(`当前页: ${val}`);
      this.getTaskList();
    },
    handperleCurrentChange(val) {
      this.pageperNum = val;
      this.taskLoad();
    },
    assPer(row){
      // console.log(row)
      this.taskId=row.taskId
      this.taskLoad()
    },
    async taskLoad(){
      let data = _qs.stringify({
        conferenceId:window.sessionStorage.getItem('conferenceId'),//会议id
        taskId:this.taskId,//菜单id
        page: this.pageperNum, //页数
        size: this.pageperSize, //每页显示条数
      });

      let res = await this.$axios.findUserPlanByTid(data);
      // console.log(res);
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.data.code == 200) {
        // console.log(res)
          this.pertableData=res.data.data.records
          this.pertotal=res.data.data.total
          this.persondialogVisible=true
      } else {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
      }
    },
    persondialogVisibleClose() {
      this.persondialogVisible=false
      this.studyGroupId=''
      this.taskId=''
      this.studyStatus=''
      this.pageperNum=1
      this.multipleSelection=[]
      this.pertableData=[]
      this.$refs.multipleTable.clearSelection()
      this.getTaskList()
      this.assdepartmentId=''
    },
    async getTaskList() {
      let data = _qs.stringify({
        taskName:this.taskName,
        taskType:this.taskItem,
        page: this.pageNum, //页数
        size: this.pageSize, //每页显示条数
        conferenceId:window.sessionStorage.getItem('conferenceId'),//会议id
        statusType:'1'
      });
      let { data: res } = await this.$axios.getTaskList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-pagination{
  text-align: center;
}
/deep/ .el-dialog__body {
  text-align: inherit;
  .btnCen {
    button {
      width: 150px;
    }
  }
}

.btnDiv{
  text-align: center;
  margin-top: 20px;
  button {
    width: 150px;
  }
}

.depTitle {
  display: flex;
  margin: 0 auto;
  margin-bottom: 20px;
}
.depart-addnum {
  width: 100%;
}

.depar-dia {
  width: 100%;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}
.el-main {
  .table-title {
    display: flex;
    margin-bottom: 10px;
    .index-add {
      margin-left: 20px;
    }
  }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.text-ellipsis{
  width:380px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/deep/ .el-textarea__inner {
  width: 220px !important;
}

/deep/ .el-input__inner {
  width: 220px !important;
}

.searSty{
  display: flex;
  margin-bottom: 20px;
  float: right;
  div{
    margin-left: 5px;
  }
}
</style>
